import React, { useEffect } from "react";
//import MainMenuController from "../js/MainMenuController";
import "@popperjs/core";

import "../../../styles/main.scss";

import czechinvest from "../../../images/products/msearch/technology_incubation_logo_en.png";
import logo from "../../../images/mamaai_logo--white.svg";
import example1en from "../../../images/products/msearch/example-1-en.png";
import example2en from "../../../images/products/msearch/example-2-en.png";
import personCalling from "../../../images/products/msearch/person-calling.jpg";
import personComputer from "../../../images/products/msearch/person-on-computer.jpg";
import returnOnInvestmentEn from "../../../images/products/msearch/return-on-investment-en.png";

import Metadata from "../../../js/Metadata";
import Navbar from "../../../js/Navbar";
import Footer from "../../../js/Footer";
import AiAcademyToast from "../../../js/layout/ai_academy_toast";

import Swal from "sweetalert2";

const MSearchPage = () => {
    const contactFormUrl =
        "https://mlh0rbexm3.execute-api.us-east-2.amazonaws.com/default/ContactUsLog?TableName=ContactUsLogs";
    //const contactFormUrl = "https://httpbin.org/post"

    const description =
        "mSearch is product of The MAMA AI enabling search by natural language.";

    async function fetchUrl(url, data) {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *folslow, error
            referrer: "client", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        await response.json();
        Swal.fire({
            title: "Thank you!",
            text: "Your message has been sent!",
            icon: "success",
            confirmButtonColor: "#FAA847",
        });
    }

    useEffect(() => {
        const handleDemoSubmit = (event) => {
            event.preventDefault();

            const contactFormData2 = {
                Item: {
                    topic: {
                        S: "Request a demo",
                    },
                    email: {
                        S: document.getElementById("sender-email2").value,
                    },
                    message: {
                        S: document.getElementById("message-text2").value,
                    },
                },
                TableName: "ContactUsLogs",
            };

            fetchUrl(contactFormUrl, contactFormData2);
        };
        document
            .getElementById("submit-btn2")
            .addEventListener("click", handleDemoSubmit);
    }, []);

    return (
        <div className="container-max-width">
            <Metadata
                title="THE MAMA AI mSearch"
                url="https://themama.ai/products/msearch/en"
                description={description}
            />
            <Navbar />
            <section className="ai-logo-section section g-0 pb-5">
                <div>
                    <div id="home" className="section__anchor" />
                    <header className="text-center logo-header m-auto">
                        <a href="/#home">
                            <img
                                className="logo-header__logo d-block m-auto"
                                src={logo}
                                alt="The mama AI"
                            />
                        </a>
                        <h1 className="fs-3 pt-5 text--color-2">mSearch</h1>
                    </header>
                </div>
            </section>
            <section
                className="section msearch-details-section pb-5 pt-5 section--light english-translation"
                id="english-version"
            >
                <div className="container pt-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <h1 className="pt-3 text-center">
                                from searching to understanding
                            </h1>
                            <p className="pt-3 text-center subtitle">
                                mSearch is product of The MAMA AI enabling
                                search by natural language
                            </p>
                            <p className="pt-4">
                                What can your search look like? Your question
                                will be answered correctly, even though it does
                                not contain any word that appears in the answer.
                            </p>
                        </div>
                        <div className="col-lg-11 pt-3 pb-3">
                            <img
                                src={example1en}
                                alt="Example 1 in english"
                                className="img-fluid rounded"
                            />
                        </div>
                        <div className="col-lg-8 position-relative">
                            <p className="pt-3">
                                Natural Language Understanding (NLU) increases
                                efficiency and user experience in the following
                                areas:
                            </p>
                            <ul className="custom-ul">
                                <li>
                                    search in the large documents databases –
                                    Document Management System, technical
                                    standards, process descriptions, procedures
                                    documentation or solutions used in
                                    maintenance, etc.
                                </li>
                                <li>
                                    understanding of the client’s request - even
                                    if the request is not formulated precisely,
                                    the client can be directed to the right
                                    document, person or department
                                </li>
                                <li>
                                    Human-to-Digital and Digital-to-Digital
                                    communication is possible
                                </li>
                            </ul>
                            <p className="pt-4">
                                The question can be placed in a language other
                                than the language of the database being
                                searched. Semantic search is language
                                independent.
                            </p>
                        </div>
                        <div className="col-lg-11 pt-3 pb-3">
                            <figure className="figure">
                                <img
                                    src={example2en}
                                    alt="Explanation: Question in this query is in Romanian and means “How do we know it's our fault?”"
                                    className="img-fluid figure-img rounded"
                                />
                                <figcaption className="figure-caption text-center">
                                    Explanation: Question in this query is in
                                    Romanian and means “How do we know it's our
                                    fault?”
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section msearch-details-section pb-5 pt-5">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 pt-3">
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-lg-10 col-xl-8">
                                    <blockquote className="blockquote text-center text--color-2">
                                        <h1>
                                            How did we deal with this
                                            recently...?
                                            <br />
                                            Someone has already asked me to do
                                            this…
                                            <br />
                                            I'm sure I've seen this situation
                                            before…
                                        </h1>
                                    </blockquote>
                                    <p className="text-center subtitle">
                                        mSearch will help you to excite your
                                        clients for instance in these
                                        situations:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-lg-6 col-xl-4 align-self-center order-lg-2">
                                    <p>
                                        The client's request to the call center
                                        will be understood without waiting and
                                        the operator will have the relevant
                                        information available when the client
                                        connects. For example, how similar
                                        requests have been successfully
                                        addressed to other clients.
                                    </p>
                                    <p className="pt-3">
                                        Sometimes the client's request is
                                        resolved even before the connection with
                                        the operator.
                                    </p>
                                </div>
                                <div className="col-lg-4 col-xl-3 order-lg-1">
                                    <img
                                        src={personCalling}
                                        alt="Person calling"
                                        className="img-fluid rounded"
                                    />
                                </div>
                            </div>
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-lg-6 col-xl-4 align-self-center">
                                    <p>
                                        Do you need to find a situation in your
                                        company records similar to the one you
                                        need to solve for your client, but
                                        perhaps described in other words? For
                                        example, traffic accidents caused by
                                        animals? mSearch will find similar
                                        situations for you, whether it's an IT
                                        issue or an accident report. And you
                                        don't have to know the language of the
                                        document you're looking for to find it.
                                    </p>
                                </div>
                                <div className="col-xl-3 col-lg-4">
                                    <img
                                        src={personComputer}
                                        alt="Person on computer"
                                        className="img-fluid rounded"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-6 pt-5">
                            <blockquote className="blockquote text-center">
                                <p className="text--color-2">
                                    "I was driving through a fenced pasture and
                                    suddenly a cow attacked me."
                                </p>
                                <p className="text--color-2">
                                    "I managed to miss the deer on the road, but
                                    suddenly he shook his head and smashed my
                                    mirror and scratched my left front door."
                                </p>
                                <p className="text--color-2">
                                    "I tried too hard not to cross the dog."
                                </p>
                            </blockquote>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-3">
                        <div className="col-lg-4 order-lg-2 align-self-center">
                            <p className="text-center">
                                Are you interested in the RoI of your AI
                                project?
                                <br /> We would be happy to work with you on
                                your data to find out which month of your
                                project is the break-even point triggering the
                                earnings.
                            </p>
                        </div>
                        <div className="col-lg-6 col-xl-5 pt-3 pb-3 order-lg-1 text-center">
                            <img
                                src={returnOnInvestmentEn}
                                alt="mAI platform Return on Investment"
                                className="img-fluid rounded"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-5 pt-5 msearch-details-section section--light contact-us-section">
                <div className="container px-8">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-lg-6 align-self-center">
                            <h1 className="pt-1">
                                We can supply also voice communication
                                technologies.
                            </h1>
                            <h2 className="pt-3">
                                Actually, we have numerous excellent AI
                                products. Would you like to buy some?
                            </h2>
                        </div>
                        <div className="col-12 col-lg-4 offset-xl-1">
                            <form>
                                <h1
                                    className="modal-title"
                                    id="requestADemoLabel"
                                >
                                    Request a demo
                                </h1>
                                <div className="mb-3">
                                    <label
                                        htmlFor="sender-email2"
                                        className="col-form-label"
                                    >
                                        E-mail:
                                        <span className="form-asterisk">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="sender-email2"
                                        required
                                    />
                                    <label
                                        htmlFor="message-text2"
                                        className="col-form-label"
                                    >
                                        Message: (optional)
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="message-text2"
                                        rows="3"
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary text-white main-navbar__demo-button modal-button"
                                    id="submit-btn2"
                                >
                                    Send message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-3 msearch-details-section section--light msearch-footer-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-auto">
                            <img
                                src={czechinvest}
                                alt="Czech Invest"
                                className="img-fluid msearch-footer-section__logo"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4 align-self-center">
                            <p className="mb-0 mt-4 mt-md-0">
                                This project was implemented with the financial
                                support of the Technology Incubation Programme.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <AiAcademyToast />
            <Footer />
        </div>
    );
};

export default MSearchPage;
